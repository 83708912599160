import H1 from 'components/Layout/H1';
import GreyContainer from 'components/Layout/GreyContainer';
import MainPicture from 'components/Layout/MainPicture';
import main from 'images/static/discover/beach.png';
import history from 'images/static/discover/christopher_columbus.png';
import React, { ReactElement } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

const Trip = (): ReactElement => {
    const { t, i18n } = useTranslation('static');

    return (
        <React.Fragment>
            <Helmet>
                <meta
                    property="og:url"
                    content={`${process.env.REACT_APP_SITE_URL}${
                        i18n.language === 'fr' ? '/saint-barth/decouvrir' : '/saint-barth/discover'
                    }`}
                />
                <meta property="og:site_name" content={t('static:discover.meta.og.siteName')} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={t('static:discover.meta.og.title')} />
                <meta property="og:description" content={t('static:discover.meta.og.description')} />
                <meta
                    property="og:image"
                    content={`${process.env.REACT_APP_SITE_URL}images/static/discover/beach.png`}
                />
                <meta property="twitter:title" content={t('static:discover.meta.twitter.title')} />
                <meta property="twitter:description" content={t('static:discover.meta.twitter.description')} />
                <meta
                    property="twitter:image"
                    content={`${process.env.REACT_APP_SITE_URL}images/static/discover/beach.png`}
                />
                <meta name="twitter:card" content="summary" />
                <meta property="title" content={t('static:discover.meta.title')} />
                <title>{t('static:discover.meta.title')}</title>
                <meta property="description" content={t('static:discover.meta.description')} />
            </Helmet>
            <Container fluid>
                <div>
                    <MainPicture src={main} alt="beach" />
                </div>
                <Container>
                    <Row>
                        <Col xs={12} className="text-center">
                            <H1>
                                <Trans t={t}>static:discover.title</Trans>
                            </H1>
                            <h5 className="fw-light">
                                <Trans t={t}>static:discover.introduction</Trans>
                            </h5>
                        </Col>
                    </Row>
                </Container>
                <GreyContainer className="mt-5">
                    <Container>
                        <Row className="py-5">
                            <Col xs={12} xl={4}>
                                <Image
                                    src={history}
                                    alt="saint barth chirstopher colombus"
                                    fluid
                                    className="mx-auto d-block"
                                />
                            </Col>
                            <Col xs={12} xl={8} className="pt-4">
                                <p>
                                    <Trans t={t}>static:discover.paragraph1</Trans>
                                </p>
                                <p>
                                    <Trans t={t}>static:discover.paragraph2</Trans>
                                </p>
                                <p>
                                    <Trans t={t}>static:discover.paragraph3</Trans>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </GreyContainer>
            </Container>
        </React.Fragment>
    );
};

export default Trip;
